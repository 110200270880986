import { userInfoTypes } from "../../components/Header";
import { ExportFetchResponse, fetchSearchTypes, partyFetchResponse, transactionBodyType } from "../../interfaces/IAPITypes";
import axios, { AxiosRequestConfig } from 'axios';
const PUBLIC_DNS = process.env.REACT_APP_PUBLIC_DNS


export const fetchPartyNames = async (): Promise<partyFetchResponse> => {
  try {
    const URL = `${PUBLIC_DNS}/transaction`;
  const response = await fetch(URL);
  const result = response.json();

  return result;
  } catch (err: any) {
    return err.message
  }
}

export const fetchExportData = async (): Promise<ExportFetchResponse> => {
  try {
    const URL = `${PUBLIC_DNS}/export`;
  const response = await fetch(URL);
  const result = response.json();

  return result;
  } catch (err: any) {
    return err.message
  }
}


export const fetchPartysById = async (id: number) => {
  try {
    const URL = `${PUBLIC_DNS}/transaction/asset/${id}`;
    const response = await fetch(URL);
    const result = response.json();
    return result;
  } catch (err: any) {
    return err.message
  }
}


export const createTransaction = async (items: transactionBodyType[]) => {
    try {
      const url = `${PUBLIC_DNS}/transaction/T`;

      const request = await fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          datas: items }),
      });

      await request.json();

      return 'Transaction Concluded';
    } catch (error: any) {
      return error.message;
    }
}

export const uploadFile = async (file: File) => {
  try {
    const formData = new FormData();
    formData.append('file', file);

    const response = await axios.post(`${PUBLIC_DNS}/create`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data.message;
  } catch (error: any) {
    return error.message;
  }
};

export const fetchSearch = async (): Promise<fetchSearchTypes[]> => {
  try {
    const get = await axios.get(`${PUBLIC_DNS}/search`);

    return get.data;
  } catch (error: any) {
    return error.message;
  }
};

export const exportDataFetch = async (partyIds: number[], fileFormat: string) => {
  try {
    const body = {
      id: partyIds,
      format: fileFormat,
    };

    await axios.post(`${PUBLIC_DNS}/export/file`, body);

    const file = `TemplateExport.${fileFormat}`;

    await new Promise(resolve => setTimeout(resolve, 1000));

    const downloadUrl = new URL(`${PUBLIC_DNS}/export/file/${file}`);
    const link = document.createElement('a');
    link.href = downloadUrl.href;
    link.setAttribute('download', file);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    // Download section end
  } catch (error: any) {
    console.error('Error downloading file:', error);
    return error.message;
  }

};


export const fetchLogin = async (username: string, password: string) => {
  try {
    const response = await fetch(`${PUBLIC_DNS}/user/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ username, password }),
      credentials: 'include',
    });

    if (!response.ok) {
      throw new Error('Login failed.');
    }

    return await response.json();
  } catch (error: any) {
    return error.message;
  }
};

export const fetchRegister = async (username: string, password: string) => {
  try {
    const response = await fetch(`${PUBLIC_DNS}/user/register`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ username, password }),
      credentials: 'include',
    });

    if (!response.ok) {
      return false;
    }

    return await response.json();
  } catch (error: any) {
    return error.message;
  }
};

export const fetchDestroy = async (body: { userToDel: string }) => {
  try {
    const response = await fetch(`${PUBLIC_DNS}/user`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
      credentials: 'include',
    });

    if (!response.ok) {
      throw new Error('Deletion failed.');
    }

    return await response.json();
  } catch (error: any) {
    console.log(error.message);
    return error.message;
  }
};

interface userInfo {
  id?: number;
  username?: string;
  roleType?: number;
  password?: string;
}

interface userUpdate {
  userInfo: userInfo,
  colToChange: string,
  newValue: string | number
}

export const fetchUpdate = async (body: userUpdate) => {
  try {
    const response = await fetch(`${PUBLIC_DNS}/user`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
      credentials: 'include',
    })

    const data = await response.json()
    
    return data
  } catch (error: any) {
    return error.message;
  }
};

export const fetchLogout = async () => {
  try {
    const response = await fetch(`${PUBLIC_DNS}/user/logout`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    });

    if (!response.ok) {
      throw new Error('Logout failed.');
    }  
    
    return response.ok
  } catch (error: any) {
    return error.message;
  }
};

export const fetchValidate = async (): Promise<userInfoTypes | boolean> => {
  try {
    const response = await fetch(`${PUBLIC_DNS}/user/validate`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    });

    if (!response.ok) {
      return false;
    }
    
    const data =  await response.json()

    return data as userInfoTypes;
  } catch (error: any) {
    return error.message;
  }
};
