import React, { ChangeEvent, KeyboardEvent, useEffect, useState } from 'react';
import Input from '../../components/Input';
import { fetchExportData } from '../../services/Api';
import { ExportFetchTypes } from '../../interfaces/IAPITypes';
import ContentHeader from '../../components/contentBar';
import CustomTable from '../../components/ExportTable';
import { searchImg } from '../../utils/images';
import LoadingScreen from '../../components/Loadscreen';
import { PageProps } from '../Home';


const Export: React.FC<PageProps> = ({isLoading}) => {
  const [exportData, setExportData] = useState<ExportFetchTypes[]>([])
  const [searchInput,setSearchInput] = useState<string>('');
  const [filteredArr, setFilteredArr] = useState<ExportFetchTypes[]>([]);
  const [errorMsg, setErrorMsg] = useState<boolean>(false)

  useEffect(() => {
    const fetchData = async () => {
      const { response } = await fetchExportData()
      console.log(`test`);
      
      setExportData(prev => prev = response)
    }

    fetchData()
  }, [])

  const handleEnviarClick = () => {
    const filter = exportData.filter((itm) => itm.partyName.toLowerCase().includes(searchInput.toLowerCase()))
    if (filter.length === 0) {
      setErrorMsg(true);
      setSearchInput(prev => prev = "");
    } else {
      setFilteredArr(prev => prev = filter);
      setSearchInput(prev => prev = "");
    }
  };

  const newData = () => {
    const currentArr =  filteredArr.length > 0 ? filteredArr : exportData;
    return currentArr.map((itm) =>
    ({
      id: itm.partyID,
      isSelected: false,
      owningEntity: itm.partyName,
      informalGroup: itm.informalGroup_ID,
      incorporationStateId: itm.partyIncorporationStateID,
      numOfProperties: itm.num_assets,
      numOfTransaction:  itm.num_transactions,
    }))
  };

  const testRow = newData();

  if (isLoading) {
    return (<LoadingScreen />)
  }

  return (
    <section className="container mx-auto h-screen mt-7">
        <ContentHeader>
        <h1 className=' text-4xl font-bold text-[#203375]'>Export Data</h1>
        </ContentHeader>
      <div className="py-6 mx-auto flex flex-col items-center">
        <div className="flex flex-col">
          <div className="flex container justify-end gap-5 items-center align-middle">
            <Input 
              inputLabelTextContent=''
              inputImgPadding='pl-10'
              inputType="text" 
              inputName="owningEntityInput" 
              className={errorMsg ? 'rounded-lg py-1 border-2 outline-none border-red-700': 'rounded-lg py-1 border border-gray-400 focus:outline-none focus:border-blue-300'}
              inputValue={searchInput}
              onChange={ (e: ChangeEvent<HTMLInputElement>) => {
                setSearchInput(prev => prev = e.target.value)
                setErrorMsg(prev => prev = false)
               } }
              onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
                if (e.key === "Enter") {
                  handleEnviarClick()
                }
              }}
              inputPlaceholder={errorMsg ? 'Not Found' : 'Search for party'}
              flex
              inputImg={searchImg}
            />
          </div>
          <div className='rounded w-full my-5 container'>
            <CustomTable
              data={testRow}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Export;  
