import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes, useMatch, useNavigate } from 'react-router-dom';
import MyProvider from './providers/GlobalProvider';
import Home from './pages/Home';
import Header, { userInfoTypes } from './components/Header';
import Import from './pages/Import';
import Transaction from './pages/Transaction';
import Export from './pages/Export';
import Localization from './providers/LocalizationProvider';
import Login from './pages/Login';
import { fetchValidate } from './services/Api';
import AdminPage from './pages/Admin';
import Error404 from './pages/ErrorPage';

const AppRoutes: React.FC = () => {
  const isHomePage = useMatch('/');
  const isErrorPage = useMatch('*');
  const [isLoading, setIsLoading] = useState(true);
  const  [userInfo, setUserInfo] = useState<userInfoTypes>({
    username: '',
    roleType: 0,
    id: 0,
  })
  const navigate = useNavigate()

  useEffect(() => {
    const validateTokenAndRedirect = async () => {
      try {
        const isValidToken = await fetchValidate() as userInfoTypes;
        if (!isValidToken) {
          navigate('/');
        } else {
          setUserInfo(prev => ({ ...prev, id: isValidToken.id,
            username: isValidToken.username,
            roleType: isValidToken.roleType
          }
        )
      )
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Error while validating token:", error);
        navigate('*');
      }
    };
    validateTokenAndRedirect();
  }, [navigate]);

  return (
    <MyProvider>
      {(!isErrorPage || !isHomePage) ? <Header userInfo={userInfo} /> : null}
      <main>
        <Routes>
          <Route path='/' element={<Login />} />
          {
            isLoading ? null : (
          <>
          <Route path='/home' element={<Home isLoading={isLoading} />} />
          <Route path='/import' element={<Import isLoading={isLoading} />} />
          <Route path='/transaction' element={<Transaction isLoading={isLoading} />} />
          <Route path='/export' element={<Export isLoading={isLoading} />} />
          </>
            )
          }
          { userInfo.roleType === 2 ? <Route path='/admin'  element={<AdminPage />}  /> : null }
          {/* <Route path='*' element={<Error404 />} /> */}
        </Routes>
      </main>
    </MyProvider>
  );
};

const App: React.FC = () => {
  return (
    <Localization>
      <BrowserRouter>
        <AppRoutes />
      </BrowserRouter>
    </Localization>
  );
};

export default App;
