import React from 'react';
import { EventTypes } from '../../interfaces/ComponentsTypes';

interface ModalProps extends EventTypes<HTMLDivElement> {
  onClose: () => void;
}

const Modal: React.FC<ModalProps> = ({ onClose }) => {
  return (
    <div className="modal fixed inset-0 flex items-center justify-center z-[100]">
      <div className="modal-content flex flex-col justify-center relative bg-white h-40 items-center p-5 w-80  rounded shadow">
        <span className="close absolute top-2 right-4 cursor-pointer" onClick={onClose}>&times;</span>
        <p className='text-center'>Under development</p>
      </div>
    </div>
  );
};

export default Modal;