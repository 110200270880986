import React, { MouseEvent, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { arrowDownImg, logoImg, vectorImg } from "../../utils/images";
import MyButton from "../Button";
import { fetchLogout, fetchValidate } from "../../services/Api";

export interface userInfoTypes {
  username: string,
  roleType: number,
  id: number
}

interface HeaderProps {
  userInfo: userInfoTypes
}

const Header: React.FC<HeaderProps> = ({userInfo}) => {
  const [user, setUser] = useState<userInfoTypes>({
    id: 0,
    username: '',
    roleType: 0
  })
  const [isOpen, setIsOpen] = useState(false)
  const navigate = useNavigate();

  const logout = async () => {
    await fetchLogout();
    const validate = await fetchValidate();
    if (validate === false) {
      navigate('/')
    }
  }

  const dropdownRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    // Function to close dropdown when clicked outside
    const handleOutsideClick: EventListener = (event) => {
      if (
        dropdownRef.current &&
        event.target instanceof Element &&
        !dropdownRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    // Attach the event listener
    document.addEventListener("mousedown", handleOutsideClick);

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    setUser(userInfo);
  }, [userInfo])

  return (
    <header className="w-full m-auto bg-white">
      <nav className="container mx-auto h-[146px] my-auto items-center flex justify-between align-middle">
        <div className="flex justify-center items-center">
          <img src={logoImg} alt="found yeti logo" />
          <img src={vectorImg} alt="" />
        </div>
        <div className="flex align-middle gap-4">
          <ul className="flex">
            <li
              onClick={() => {
                navigate("/home")
                setIsOpen(false)
              }}
              className={`${
                window.location.pathname === "/home" ? "text-[#203375] font-extrabold" : ""
              } cursor-pointer  text-center w-[7rem] py-3 text-[12px]`}
            >
              SEARCH DATA
            </li>
            <li
              onClick={() => {
                navigate("/export");
                setIsOpen(false)}}
              className={`${
                window.location.pathname === "/export" ? "text-[#203375] font-extrabold" : ""
              } cursor-pointer  text-center w-[7rem] py-3 text-[12px]`}
            >
              EXPORT DATA
            </li>
            <li
              onClick={() => {navigate("/import"); setIsOpen(false);}}
              className={`${
                window.location.pathname === "/import" ? "text-[#203375] font-extrabold" : ""
              } cursor-pointer  text-center w-[7rem] py-3 text-[12px]`}
            >
              IMPORT DATA
            </li>
            <li
              onClick={() => {navigate("/transaction"); setIsOpen(false);}}
              className={`${
                window.location.pathname === "/transaction" ? "text-[#203375] font-extrabold" : ""
              } cursor-pointer  text-center w-[7rem] py-3 text-[12px] transition-transform`}
            >
              TRANSACTION
            </li>
          </ul>
          <div className="relative align-middle flex before:flex before:content-['|'] before:mt-2 before:align-middle before:pr-6 before:font-normal">
            <div className=" flex gap-3 align-middle border border-gray-400 transition-all hover:border-gray-600 items-center pr-2.5 pl-4 rounded-full mx-auto" onClick={() => setIsOpen(prev => !prev)}>
            <p className="text-sm font-bold ">{user.username}</p>
            {/* <MyButton
              text="Sigh out"
              onClick={ () => {
                logout()
              } }
              className="text-sm bg-[#203375] hover:bg-[#2C4390] text-white py-1 px-3 rounded"
            /> */}
            <img src={arrowDownImg} alt="arrow" width={12} className={`transition-all mt-1 ${isOpen ? 'rotate-180' : 'rotate-0'}`}  />
            </div>
            <div ref={dropdownRef} className={`absolute transition-[visible,invisible] ${isOpen ? "visible" : "invisible"} top-12 bg-white w-44 right-0 px-0.5 pb-4 text-right border border-gray-400 rounded`}>
              <ul className="flex flex-col text-sm">
                { user.roleType === 2 ? <li
              onClick={() => {navigate("/admin"); setIsOpen(false)} }
              className={`${
                window.location.pathname === "/admin" ? "text-[#203375] font-bold" : ""
              } border-b border-gray-400 p-2 hover:bg-[#D9E7FB] cursor-pointer`}
            >
              Admin Panel
            </li> : null}
                <li onClick={ () => logout() } className="cursor-pointer p-2 border-b border-gray-400 hover:bg-[#D9E7FB]">Sign out</li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
