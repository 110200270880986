import React, { useState } from "react";
import ContentHeader from "../../components/contentBar";
import Input from "../../components/Input";
import MyButton from "../../components/Button";
import { upgradeImg } from "../../utils/images";
import { fetchDestroy, fetchUpdate } from "../../services/Api";
import Select, { ActionMeta, Props, SingleValue } from 'react-select'
import { customOption } from "../../interfaces/ITransactionTypes";

const options = [
  { id: '1' ,value: 'username', label: 'Username' },
  { id: '2' ,value: 'role', label: 'Role' },
];

const AdminPage: React.FC = () => {

  const [username, setUsername] = useState("");
  const [delUser, setDelUser] = useState("");
  const [newValue, setNewValue] = useState("");
  const [errorMsgUpdate, setErroMsgUpdate] = useState("");
  const [errorMsgDelete, setErroMsgDelete] = useState("");
  const  [colToChange, setColToChange] = useState('');

  const handleUpdate = async () => {
    const body = {
      userInfo: {
        username: username,
      },
      colToChange: colToChange,
      newValue: colToChange === 'role' ? Number(newValue) : String(newValue),
    }
    const fetch = await fetchUpdate(body);
    if (fetch !== 'Update success')
    setErroMsgUpdate(fetch.message) 
  }

  const handleDelete = async () => {
    const fetch = await fetchDestroy({ userToDel: delUser});

    setErroMsgDelete(fetch.message)

  }

  const handleSelectChange = () => (selectedOption: SingleValue<customOption>, actionMeta: ActionMeta<customOption>): void => {
    setErroMsgUpdate('');
    setColToChange(selectedOption?.value || '')
  };

  // const handleSelectChange = (key: formKeys) => (selectedOption: SingleValue<customOption>, actionMeta: ActionMeta<customOption>): void => {
  //   setTransactionForm(prevState => ({
  //     ...prevState,
  //     [key]: {
  //       ...prevState[key],
  //       inputValue: selectedOption?.value ?? '',
  //       id: selectedOption?.id ?? ''
  //     },
  //   }));
  // };

  return (
    <>
      <section className="container mx-auto mt-16">
        <ContentHeader>
          <h1 className=" text-4xl font-bold text-[#203375]">Admin Panel</h1>
        </ContentHeader>
        <div className="bg-white">
        <div className="mt-5 mx-auto w-fit flex flex-col gap-5 rounded-md min-h-[30rem]">
          <div className="flex flex-col gap-3 w-fit">
            <h1 className="font-bold text-[#203375] text-2xl">User update</h1>
            <div>
            <Select
                inputId="colToChange"
                options={options}
                placeholder="Select info to change"
                onChange={handleSelectChange()}
                value={options.find(option => option.value === colToChange)}
                styles={{
                  control: (base, props) => ({
                    ...base,
                    height: "48px",
                    borderColor: "#3C4045",
                  }),
                  dropdownIndicator: (base, props) => ({
                    ...base,
                    color: "black",
                  }),
                }}
              />
            </div>
            <div className="flex gap-4">
              <Input
                inputName="username"
                inputType="text"
                flex
                className="w-[299px] p-2 h-[46px] outline outline-1 transition-all focus:outline-blue-500 focus:outline-2 will-change-auto outline-[#3C4045] rounded pl-3 hover:outline-gray-400"
                labelClassname="absolute top-[-25px] left-0 w-60"
                inputPlaceholder="User username"
                onChange={(e) => {setUsername((prev) => (prev = e.target.value)); setErroMsgUpdate('') }}
              />
              <Input
                inputName="newValue"
                inputType="text"
                flex
                className="w-[299px] p-2 h-[46px] outline outline-1 transition-all focus:outline-blue-500 focus:outline-2 will-change-auto outline-[#3C4045] rounded pl-3 hover:outline-gray-400"
                labelClassname="absolute top-[-25px] left-0 w-60"
                inputPlaceholder="New value"
                onChange={(e) => {setNewValue((prev) => (prev = e.target.value)); setErroMsgUpdate('') }}
              />
            </div>
            <p className="w-fit mx-auto text-sm text-[#FF0000] font-light">{errorMsgUpdate}</p>
            <MyButton onClick={() => handleUpdate()} imgWidth={10} img={upgradeImg} text="Update" className="flex gap-3 bg-blue-900 hover:opacity-95 w-1/2 mx-auto justify-center items-center text-white py-2.5 rounded" />

          </div>
          <div className="flex flex-col gap-3">
            <h1 className="font-bold text-[#203375] text-2xl">Delete user</h1>
            <div className="flex gap-4 items-center">
            <Input
                inputName="userToDel"
                flex
                inputType="text"
                className="w-[299px] p-2 h-[46px] outline outline-1 transition-all focus:outline-blue-500 focus:outline-2 will-change-auto outline-[#3C4045] rounded pl-3 hover:outline-gray-400"
                labelClassname="absolute top-[-25px] left-0 w-60"
                inputPlaceholder="Username"
                onChange={(e) => setDelUser((prev) => (prev = e.target.value))}
              />
              <MyButton onClick={() => handleDelete()} imgWidth={10} img={upgradeImg} text="Delete" className="flex gap-3 bg-blue-900 hover:opacity-95 w-1/2 mx-auto justify-center items-center text-white p-2 rounded h-[46px]" />
            </div>
              <p className="w-fit mx-auto text-sm text-[#FF0000] font-light">{errorMsgDelete}</p>
          </div>
        </div>
        </div>
      </section>
    </>
  );
};

export default AdminPage;
