import search from '../images/search.svg';
import searchWhite from '../images/searchWhite.svg';
import logo from '../images/image 1.svg';
import calendar from '../images/calendar_month.svg';
import visibility from '../images/visibility.svg';
import swapVert from '../images/swap_vert.svg';
import viewWeek from '../images/view_week.svg';
import vector from '../images/Vector.svg';
import edit from '../images/edit.svg';
import arrowDown from '../images/arrow_drop_down.svg';
import upgrade from '../images/upgrade.svg';
import download from '../images/download.svg';
import attach  from '../images/attach_file.svg';
import cached from '../images/cached.svg';
import check from '../images/check_box.svg';

export const searchImg = search;
export const searchWhiteImg = searchWhite;
export const logoImg = logo;
export const calendarImg = calendar;
export const visibilityImg = visibility;
export const swapVertImg = swapVert;
export const viewWeekImg = viewWeek;
export const vectorImg = vector;
export const editImg = edit;
export const arrowDownImg = arrowDown;
export const upgradeImg = upgrade;
export const downloadImg = download;
export const attachImg = attach;
export const cachedImg = cached;
export const checkImg = check;
