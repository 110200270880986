import React from "react";
import { formSelectTypes } from "../../interfaces/IFormTypes";
import { v4 as uuidv4 } from 'uuid'
import { swapVertImg } from "../../utils/images";

const Select: React.FC<formSelectTypes> = ({ selectName, selectValue, selectLabelTextContent,
  selectOptions, className, optionClassName, onChange, onKeyDown }) => {
  return (
    <div className=" flex flex-col justify-between items-center w-fit gap-2 align-middle whitespace-nowrap">
      <div className="flex items-center justify-center gap-2">
        <label htmlFor={selectName} className="text-xs font-normal flex flex-col justify-center items-center">
      {selectLabelTextContent}
      </label>
      <img src={swapVertImg} alt="swap icon"  className="w-3"/>
      </div>
      <select
        name={selectName}
        id={selectName}
        value={selectValue}
        className={className}
        onChange={onChange}
        onKeyDown={onKeyDown}
      >
        {selectOptions.map((itm) => (
          <option
            key={uuidv4()}
            value={itm}
            className={optionClassName}
          >
            {itm}
          </option>
        ))}
      </select>
    </div>
  )
}

export default Select;