import React, { useEffect, useState } from "react";
import { logoImg, vectorImg } from "../../utils/images";
import Input from "../../components/Input";
import MyButton from "../../components/Button";
import { fetchLogin, fetchRegister, fetchValidate } from "../../services/Api";
import { useNavigate } from "react-router-dom";

const Login: React.FC = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [openRegister, setOpenRegister] = useState(false);
  const [regUsername, setRegUsername] = useState("");
  const [regPassword, setRegPassword] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const validate = async () => {
      const get = await fetchValidate();
      if (get) {
        navigate("/home")
      }
    }
    validate()
  }, [navigate])

  const toogleRegister = () => {
    setOpenRegister((prev) => prev = true);
  };

  const closeRegister = () => {
    setOpenRegister((prev) => prev = false);
  };

  return (
    <section className="relative max-h-screen">
      <div>
        <div className="bg-white py-10 px-8 flex flex-col rounded-md w-[40%] min-h-[30rem] m-auto my-52 shadow-2xl shadow-[#00000061]">
          <div className="w-fit mx-auto">
            <img src={logoImg} alt="Yeti logo" className="mx-auto" width={90} />
            <img src={vectorImg} alt="" width={110} />
          </div>
          <div className="mx-auto w-fit justify-center mt-8 flex flex-col gap-6">
            <Input
              inputName="username"
              inputType="text"
              inputPlaceholder="Username"
              inputValue={username}
              onChange={(e) => {
                setUsername((prev) => (prev = e.target.value));
              }}
              className="border focus:outline-blue-500 border-black px-4 py-2 rounded"
            />
            <Input
              inputName="password"
              inputType="password"
              inputPlaceholder="Password"
              inputValue={password}
              onKeyDown={ async (e) => {
                  if (e.key === 'Enter') {
                    await fetchLogin(username, password);
                    navigate("/home")
                  }
                }}
              onChange={(e) => {
                setPassword((prev) => (prev = e.target.value));
              }}
              className="border focus:outline-blue-500 border-black px-4 py-2 rounded"
            />
          <MyButton
                text="Login"
                className="bg-blue-900 hover:opacity-95 w-full text-white py-2.5 rounded"
                onClick={ async () => {
                  await fetchLogin(username, password);
                  navigate("/home")
                }}
              />
          </div>
          <div className="text-sm mx-auto pt-3 mt-6 border-t border-gray-500">
            <p className="flex gap-1">
              Don't have an account yet?{" "}
              <div
                onClick={toogleRegister}
                className="text-blue-500 cursor-pointer"
              >
                Sign Up
              </div>
            </p>
          </div>
        </div>
      </div>
      <div
        className={`absolute w-full  mx-auto inset-0 flex flex-col items-center justify-center ${
          openRegister ? "z-50" : "-z-50"
        }`}
      >
        {openRegister && (
          <div className="fixed inset-0 bg-black opacity-40 transition-all will-change-auto"></div>
        )}
        
        <div
          className={`p-4 rounded-lg w-[25rem]  transform will-change-auto transition-all ${
            openRegister ? "translate-x-0" : "-translate-x-[100vw] -z-50"
          } transition-transform`}
        >
          
          <div className="bg-white px-8 flex flex-col rounded-md py-[10rem] m-auto my-52 shadow-2xl shadow-[#00000061]">
            <div className="flex mr-[15%] ml-[15%]">
            <img src={logoImg} alt="" width={90} />
            <img src={vectorImg} alt="" width={90} />
            </div>
            <div className="mx-auto relative w-fit justify-center mt-8 flex flex-col gap-6">
            <div className=" absolute top-[-15rem] right-0 ml-auto cursor-pointer" onClick={closeRegister}>
            &times;
            </div>
              <Input
                inputName="regUsername"
                inputType="text"
                inputPlaceholder="Username"
                inputValue={regUsername}
                onChange={(e) => {
                  setRegUsername((prev) => (prev = e.target.value));
                }}
                className="border focus:outline-blue-500 border-black px-4 py-2 rounded"
              />
              <Input
                inputName="regPassword"
                inputType="password"
                inputPlaceholder="Password"
                inputValue={regPassword}
                onKeyDown={ async (e) => {
                  if (e.key === 'Enter') {
                    await fetchLogin(username, password);
                    navigate("/home")
                  }
                }}
                onChange={(e) => {
                  setRegPassword((prev) => (prev = e.target.value));
                }}
                className="border focus:outline-blue-500 border-black px-4 py-2 rounded"
              />
              <div className="border-t border-gray-500 py-6">
              <MyButton
                text="Register"
                className="bg-blue-900 hover:opacity-95 w-full text-white py-2.5 rounded"
                onClick={ async () => {
                  await fetchRegister(regUsername, regPassword);
                  navigate('/home')
                }}
              />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
