import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  TablePagination,
} from "@mui/material";
import { cachedImg, calendarImg } from "../../utils/images";
import { TableFooter } from "@mui/material";
import TablePaginationActions from "../paginationComponent";
import { transactionBodyType } from "../../interfaces/IAPITypes";
import ContentHeader from "../contentBar";
import Select, { ActionMeta, SingleValue } from "react-select";
import MyContext from "../../providers/contexts/GlobalContext";
import {
  TransactionForm,
  customOption,
  formKeys,
} from "../../interfaces/ITransactionTypes";
import { ImportTableData } from "../../interfaces/ComponentsTypes";
import { createTransaction, fetchPartysById } from "../../services/Api";
import Input from "../Input";
import CustomDateInput from "../DatePickerTransaction";
import { PageProps } from "../../pages/Home";
import LoadingScreen from "../Loadscreen";

const optionsTransc = [
  { id: "1", value: "Loan", label: "Loan" },
  { id: "2", value: "Assign", label: "Assign" },
  { id: "3", value: "Transfer", label: "Transfer" },
  { id: "4", value: "Financing", label: "Financing" },
  { id: "5", value: "Release of Financing", label: "Release of Financing" },
  { id: "6", value: "License", label: "License" },
  { id: "7", value: "Revocation of License", label: "Revocation of License" },
  { id: "8", value: "Affidavit of Ownership", label: "Affidavit of Ownership" },
];

const TransactionTable: React.FC<PageProps> = ({isLoading}) => {
  const { partyNames, partyOneFetch, setPartyOneFetch } = useContext(MyContext);
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [reqItems, setReqItems] = useState<transactionBodyType[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [transcMsg, setTransacMsg] = useState("");
  const [transactionForm, setTransactionForm] = useState<TransactionForm>({
    partyOne: {
      id: 0,
      inputValue: "",
    },
    yetiTranscType: {
      inputValue: "",
    },
    transacDateSigned: {
      inputValue: "",
    },
    partyTwo: {
      id: 0,
      inputValue: "",
    },
    transacDescription: {
      inputValue: "",
    },
    transacSrcNum: {
      inputValue: "",
    },
  });

  const newData = (): ImportTableData[] => {
    const newArr = partyOneFetch.map((itm, i) => ({
      id: i,
      isSelected: false,
      propertyName: itm.assetName,
      yetiID: itm.assetIDNumber,
    }));
    return newArr;
  };

  const data: ImportTableData[] = newData();

  const options = partyNames.map((itm) => ({
    id: itm.partyID.toString(),
    value: itm.partyName,
    label: itm.label,
  }));

  const getPartyId = useCallback(
    (id: number) => {
      const find = partyNames.find((itm) => itm.partyID === id);
      return find?.partyID;
    },
    [partyNames]
  );

  useEffect(() => {
    if (
      transactionForm.partyOne.inputValue !== "" ||
      transactionForm.partyOne.inputValue.length > 0
    ) {
      const partyOneInput = transactionForm.partyOne.id;
      const formId = Number(getPartyId(Number(partyOneInput)));
      const fetchedAsset = async (id: number) => {
        const { response } = await fetchPartysById(id);
        setPartyOneFetch((prev) => (prev = response));
      };

      fetchedAsset(formId);
    }
  }, [
    transactionForm.partyOne.inputValue,
    getPartyId,
    setPartyOneFetch,
    transactionForm.partyOne.id,
  ]);

  const handleSelectChange =
    (key: formKeys) =>
    (
      selectedOption: SingleValue<customOption>,
      actionMeta: ActionMeta<customOption>
    ): void => {
      setTransactionForm((prevState) => ({
        ...prevState,
        [key]: {
          ...prevState[key],
          inputValue: selectedOption?.value ?? "",
          id: selectedOption?.id ?? "",
        },
      }));
    };
  const [transactionReqBody, setTransactionReqBody] =
    useState<transactionBodyType>({
      partyID1: 0,
      partyID2: 0,
      isTransaction: "", // nativo ou YETI,
      transactionType: "", // Loan ou outros
      assetDatas: {},
      transactionNative: {
        transactionNativeType: "",
        transactionNativeSource: "",
      },
      transactionYETI: {
        transactionYETIType: "",
        transactionYETIDateSigned: "",
        transactionYETIComments: "",
      },
    });

  useEffect(() => {
    setTransactionReqBody((prev) => ({
      ...prev,
      partyID1: Number(transactionForm.partyOne.id),
      partyID2: Number(transactionForm.partyTwo.id),
      isTransaction:
        transactionForm.transacSrcNum.inputValue.length > 0 ? "native" : "yeti",
      transactionType: transactionForm.yetiTranscType.inputValue,
      transactionNative: {
        transactionNativeType: transactionForm.yetiTranscType.inputValue,
        transactionNativeSource: transactionForm.transacSrcNum.inputValue,
      },
      transactionYETI: {
        transactionYETIType: transactionForm.yetiTranscType.inputValue,
        transactionYETIDateSigned: transactionForm.transacDateSigned.inputValue,
        transactionYETIComments: transactionForm.transacDescription.inputValue,
      },
    }));
  }, [
    transactionForm.partyOne.id,
    transactionForm.partyTwo.id,
    transactionForm.yetiTranscType.inputValue,
    transactionForm.transacSrcNum.inputValue,
    transactionForm.transacDateSigned.inputValue,
    transactionForm.transacDescription.inputValue,
  ]);

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const allItemIds = data.map((row) => row.id);
      setSelectedItems(allItemIds);
    } else {
      setSelectedItems([]);
    }
  };

  const handleChange = useCallback(
    (id: number) => {
      let updatedAssetDatas = {};

      if (String(transactionReqBody.transactionType) === 'Loan' || String(transactionReqBody.transactionType) === 'License') {
        updatedAssetDatas = {
          assetIDType: partyOneFetch[id].assetIDType,
          assetName: partyOneFetch[id].assetName,
          assetRegistrationNumber:  partyOneFetch[id].assetRegistrationNumber,
          assetRegistrationDate: partyOneFetch[id].assetRegistrationDate,
          assetStatus:  partyOneFetch[id].assetStatus,
          assetDescription:  partyOneFetch[id].assetDescription,
          assetComments:  partyOneFetch[id].assetComments,
          asset_ParentIDType: [partyOneFetch[id].asset_ParentIDType],
          asset_ParentIDNumber: partyOneFetch[id].assetIDNumber,
          asset_OriginalParentIDType: partyOneFetch[id].asset_OriginalParentIDType,
          asset_OriginalParentIDNumber: partyOneFetch[id].asset_OriginalParentIDNumber,
          RelationshipPartyToAssets: partyOneFetch[id].RelationshipPartyToAssets
        }
      } else {
        updatedAssetDatas = {
          assetIDType: partyOneFetch[id].assetIDType,
          assetIDNumber: partyOneFetch[id].assetIDNumber,
        }
      }

      if (selectedItems.includes(id)) {
        setSelectedItems((prevSelectedItems) =>
          prevSelectedItems.filter((itemId) => itemId !== id)
        );
        
        setReqItems((prevReqItems) =>
          prevReqItems.filter(
            (item) =>
              item.partyID1 !== transactionReqBody.partyID1 ||
              item.partyID2 !== transactionReqBody.partyID2
          )
        );
      } else {
        setSelectedItems((prevSelectedItems) => [...prevSelectedItems, id]);
        setReqItems((prevReqItems) => [
          ...prevReqItems,
          {
            partyID1: transactionReqBody.partyID1,
            partyID2: transactionReqBody.partyID2,
            isTransaction: transactionReqBody.isTransaction,
            transactionType: transactionReqBody.transactionType,
            assetDatas: updatedAssetDatas,
            transactionNative: transactionReqBody.transactionNative,
            transactionYETI: transactionReqBody.transactionYETI,
          },
        ]);
      }

      setTransactionReqBody((prev: any) => ({
        ...prev,
        assetDatas: updatedAssetDatas,
      }));
    },
    [
      partyOneFetch,
      selectedItems,
      transactionReqBody.partyID1,
      transactionReqBody.partyID2,
      transactionReqBody.isTransaction,
      transactionReqBody.transactionType,
      transactionReqBody.transactionNative,
      transactionReqBody.transactionYETI,
      setTransactionReqBody,
      setReqItems
    ]
  );

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (isLoading) {
    return (<LoadingScreen />)
  } else {

  return (
    <>
      <section className="container justify-center m-auto mt-7 flex flex-col items-center align-middle">
        <ContentHeader className="pl-[calc(40%-335px)]">
          <h3 className="text-3xl font-bold text-[#203375]">
            Transaction of Property #
          </h3>
        </ContentHeader>
        <form className="flex flex-col mx-auto mt-7 gap-7 items-center">
          <fieldset className="max-w-[980px] flex gap-10 items-center mx-auto">
            <div className="flex flex-col gap-2">
              <label htmlFor="partyOne">Party 1*</label>
              <Select
                inputId="partyOne"
                placeholder="Party Name"
                className="w-[300px]"
                value={options.find(
                  (option) =>
                    option.value === transactionForm.partyOne.inputValue
                )}
                options={options}
                onChange={handleSelectChange(formKeys.partyOne)}
                blurInputOnSelect
                isClearable
                styles={{
                  control: (base, props) => ({
                    ...base,
                    height: "48px",
                    borderColor: "#3C4045",
                  }),
                  dropdownIndicator: (base, props) => ({
                    ...base,
                    color: "black",
                  }),
                }}
              />
            </div>
            <div className="flex flex-col gap-2">
              <label htmlFor="partyTwo">Party 2*</label>
              <Select
                inputId="partyTwo"
                placeholder="Party Name"
                className="w-[300px]"
                value={options.find(
                  (option) =>
                    option.value === transactionForm.partyTwo.inputValue
                )}
                options={options}
                onChange={handleSelectChange(formKeys.partyTwo)}
                blurInputOnSelect
                isClearable
                styles={{
                  control: (base, props) => ({
                    ...base,
                    height: "48px",
                    borderColor: "#3C4045",
                  }),
                  dropdownIndicator: (base, props) => ({
                    ...base,
                    color: "black",
                  }),
                }}
              />
            </div>
            <div className="flex flex-col gap-2">
              <label htmlFor="yetiTranscType">YETI Transaction Type*</label>
              <Select
                inputId="yetiTranscType"
                className="w-[300px]"
                value={options.find(
                  (option) =>
                    option.value === transactionForm.yetiTranscType.inputValue
                )}
                options={optionsTransc}
                onChange={handleSelectChange(formKeys.yetiTranscType)}
                blurInputOnSelect
                isClearable
                isSearchable={false}
                styles={{
                  control: (base, props) => ({
                    ...base,
                    height: "48px",
                    borderColor: "#3C4045",
                  }),
                  dropdownIndicator: (base, props) => ({
                    ...base,
                    color: "black",
                  }),
                }}
              />
            </div>
          </fieldset>
          <fieldset className="max-w-[980px] flex gap-10 mt-8 items-center mx-auto">
            <Input
              inputName="transacDescription"
              inputType="text"
              className="w-[299px] p-2 h-[46px] outline outline-1 transition-all focus:outline-blue-500 focus:outline-2 will-change-auto outline-[#3C4045] rounded pl-3 hover:outline-gray-400"
              labelClassname="absolute top-[-25px] left-0 w-60"
              inputPlaceholder="Party Assigning YETI"
              inputLabelTextContent="Transaction Description"
              inputValue={transactionForm.transacDescription.inputValue}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setTransactionForm((prev) => ({
                  ...prev,
                  transacDescription: { inputValue: e.target.value },
                }))
              }
            />
            <CustomDateInput
              formContent={transactionForm}
              setFormContent={setTransactionForm}
              inputName="transacDateSigned"
              inputType="text"
              className="w-[299px] p-2 h-[46px] outline outline-1 transition-all focus:outline-blue-500 focus:outline-2 will-change-auto outline-[#3C4045] rounded pl-3 hover:outline-gray-400"
              inputPlaceholder="Select"
              flex={false}
              inputImg={calendarImg}
              inputImgPadding="pl-[2.8rem]"
              imgAlt="calender icon"
              labelClassname="absolute top-[-25px] left-0 w-60"
              inputLabelTextContent="Transaction Date Signed"
              inputValue={transactionForm.transacDateSigned.inputValue}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setTransactionForm((prev) => ({
                  ...prev,
                  transacDateSigned: { inputValue: e.target.value },
                }))
              }
            />
            <Input
              inputName="transacSrcNum"
              inputType="text"
              className="w-[299px] p-2 h-[46px] outline outline-1 transition-all focus:outline-blue-500 focus:outline-2 will-change-auto outline-[#3C4045] rounded pl-3 hover:outline-gray-400"
              labelClassname="absolute top-[-25px] left-0 w-60"
              inputPlaceholder="XXXXXXXXXXXXXX-X"
              inputLabelTextContent="Transaction Source Number"
              inputValue={transactionForm.transacSrcNum.inputValue}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setTransactionForm((prev) => ({
                  ...prev,
                  transacSrcNum: { inputValue: e.target.value },
                }))
              }
            />
          </fieldset>
        </form>
        <div className="w-[calc(100%-29vw)] mx-auto bg-white mt-10 z-0">
          <TableContainer
            style={{ maxHeight: "calc(100vh - 300px)", width: "100%" }}
          >
            <Table stickyHeader={true}>
              <TableHead>
                <TableRow>
                  <TableCell width={10}>
                    <Checkbox size="small" onChange={handleSelectAll} />
                  </TableCell>
                  <TableCell
                    style={{
                      fontWeight: "700",
                      fontSize: "12px",
                    }}
                    align="left"
                    width={200}
                  >
                    PROPERTY NAME
                  </TableCell>
                  <TableCell
                    style={{
                      fontWeight: "700",
                      fontSize: "12px",
                    }}
                  >
                    YETI ID
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(rowsPerPage > 0
                  ? data.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : data
                ).map((row) => (
                  <TableRow
                    key={row.id}
                    hover
                    selected={selectedItems.includes(row.id)}
                  >
                    <TableCell>
                      <Checkbox
                        size="small"
                        checked={selectedItems.includes(row.id)}
                        aria-checked={selectedItems.includes(row.id)}
                        onChange={() => handleChange(row.id)}
                      />
                    </TableCell>
                    <TableCell style={{ fontWeight: "700", fontSize: "14px" }}>
                      {row.propertyName}
                    </TableCell>
                    <TableCell style={{ fontSize: "14px" }}>
                      {row.yetiID}
                    </TableCell>
                  </TableRow>
                ))}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 50 * emptyRows }}>
                    <TableCell colSpan={3} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TableFooter>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50]}
              colSpan={7}
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  "aria-label": "rows per page",
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableFooter>
        </div>
        <div className="w-[64%] my-6 container flex justify-end items-center gap-3">
          <button
            type="button"
            className="border flex gap-2 disabled:opacity-50 items-center px-7 py-1 h-[3rem] rounded-[5px] bg-[#203375] text-white hover:bg-[#2c4390]"
            onClick={async () => {
              const transac = await createTransaction(reqItems);

              setTransacMsg((prev) => (prev = transac));
            }}
          >
            <img
              src={cachedImg}
              alt="arrow poined upwards to indicate an upgrade."
            />
            <p>Transaction</p>
          </button>
        </div>
        {/* <Table
        data={}
        partyOneFetch={partyOneFetch}
        partyOne={Number(transactionForm.partyOne.id)}
        partyTwo={Number(transactionForm.partyTwo.id)}
        isTransaction={transactionForm.transacSrcNum.inputValue.length > 0 ? 'native' : 'yeti'}
        transactionYETIComments={transactionForm.transacDescription.inputValue}
        transactionYETIDateSigned={transactionForm.transacDateSigned.inputValue}
        transactionYETIType={transactionForm.yetiTranscType.inputValue}
        srcNumber={transactionForm.transacSrcNum.inputValue}
         /> */}
      </section>
    </>
  );
        }
};

export default TransactionTable;
