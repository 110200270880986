import React, { FC, ReactNode } from "react";

interface contentHeaderProps {
  children: ReactNode,
  className?: string
}

const ContentHeader: FC<contentHeaderProps>  = ({children, className}) => {
  return (<div className={`w-full flex justify-between bg-[#91A3E1] mx-auto h-[75px] rounded items-center px-7 ${className}`}>
    {children}
  </div>)
}

export default ContentHeader;
