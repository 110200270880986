import { GroupBase, Props } from "react-select";

export type customOption = {
  id?: string;
  value: string;
  label: string;
}

export enum formKeys {
partyOne = 'partyOne',
yetiTranscType = 'yetiTranscType',
transacDateSigned = 'transacDateSigned',
partyTwo = 'partyTwo',
transacDescription = 'transacDescription',
transacSrcNum = 'transacSrcNum'
}

export interface selectOption extends Props<GroupBase<customOption>> {}

export type TransactionForm = {
  [key in formKeys]: {
    inputValue: string;
    id?: number | string
  };
}