import React, { useRef, useState } from "react";
import { attachImg } from "../../utils/images";

interface FileInputProps {
  onChange: (file: File | null) => void;
}

const FileInput: React.FC<FileInputProps> = ({ onChange }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [selectedFile, setSelectedFile] = useState<File |  null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const selectedFile = files[0];
      setSelectedFile(prev => prev = files[0])
      const allowedExtensions = ["xlsx", "xlsm", "csv"];
      const fileExtension = selectedFile.name.split(".").pop()?.toLowerCase();

      if (fileExtension && allowedExtensions.includes(fileExtension)) {
        onChange(selectedFile);
      } else {
        onChange(null);
        alert("Invalid file format. Please select an xlsx, xlsm, or csv file.");
      }
    } else {
      onChange(null);
    }
  };

  const handleButtonClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  return (
    <div className="flex flex-col items-center justify-center text-center">
      <input
        type="file"
        className="hidden"
        ref={inputRef}
        accept=".xlsx, .xlsm, .csv"
        onChange={handleFileChange}
      />
      <div className="relative flex flex-col items-center justify-center">
        <p className="absolute top-[calc(-20%-22px)] text-left self-start text-base font-normal">Select Excel File</p>
        <input
          type="text"
          value="Choose file"
          readOnly
          className="border rounded border-gray-400 text-center px-[8.4rem] py-3.5 text-sm leading-tight focus:outline-none focus:border-blue-500 cursor-pointer bg-[#E2E2E2] hover:bg-gray-200"
          onClick={handleButtonClick}
        />
        <img
          src={attachImg}
          alt="File Icon"
          className="absolute top-1/2 left-[calc(50%-62px)] transform -translate-y-1/2 w-6 h-6 cursor-pointer"
          onClick={handleButtonClick}
        />
        <p className="absolute left-[30%] right-[30%] top-[calc(60%+28px)] self-start text-sm font-thin">{ 
        selectedFile && selectedFile.name.length > 0 ? `${selectedFile.name}` : "No file selected" }</p>
      </div>
    </div>
  );
};

export default FileInput;
