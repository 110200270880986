import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  TablePagination,
} from "@mui/material";
import { editImg, upgradeImg } from "../../utils/images";
import { exportDataFetch } from "../../services/Api";
import Select, { ActionMeta, SingleValue } from "react-select";
import { customOption } from "../../interfaces/ITransactionTypes";
import { TableFooter } from "@mui/material";
import TablePaginationActions from "../paginationComponent";
import Modal from "../Modal";

interface RowData {
  id: number;
  owningEntity: string;
  informalGroup: string | number | null;
  incorporationStateId: string | number | null;
  numOfProperties: number;
  numOfTransaction: number;
}

interface CustomTableProps {
  data: RowData[];
}

const CustomTable: React.FC<CustomTableProps> = ({ data }) => {
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [fileType, setFileType] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [disabled, setDisabled] = useState(true);
  const [showModal, setShowModal] = useState(false);

  const handleIconClick = () => {
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };

  const options = [
    { value: "xlsx", label: "XLSX" },
    { value: "xlsm", label: "XLSM" },
    { value: "csv", label: "CSV" },
  ];

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const allItemIds = data.map((row) => row.id);
      setSelectedItems(allItemIds);
    } else {
      setSelectedItems([]);
    }
  };

  const handleSelectRow = (id: number) => {
    if (selectedItems.includes(id)) {
      setSelectedItems((prevSelectedItems) =>
        prevSelectedItems.filter((itemId) => itemId !== id)
      );
    } else {
      setSelectedItems((prevSelectedItems) => [...prevSelectedItems, id]);
    }
  };

  const handleSelectChange =
    () =>
    (
      selectedOption: SingleValue<customOption>,
      actionMeta: ActionMeta<customOption>
    ): void => {
      setFileType((prev) => (prev = selectedOption?.value ?? "xlsx"));
    };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    if (fileType === "" || fileType.length <= 0 || selectedItems.length <= 0) {
      setDisabled((prev) => true);
    } else {
      setDisabled((prev) => false);
    }
  }, [fileType, selectedItems]);

  return (
    <>
    { showModal && (<>
          <div className="fixed inset-0 bg-black opacity-50 z-[99]" onClick={handleCloseModal} />
          <Modal onClose={handleCloseModal} />
        </>)}
      <div className="w-full bg-white">
        <TableContainer
          style={{ maxHeight: "calc(100vh - 300px)", width: "100%" }}
        >
          <Table stickyHeader={true}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Checkbox size="small" onChange={handleSelectAll} />
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "700",
                    paddingRight: "5rem",
                    fontSize: "12px",
                  }}
                >
                  CURRENT OWNER
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "700",
                    paddingRight: "5rem",
                    fontSize: "12px",
                  }}
                >
                  INFORMAL GROUP
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "700",
                    paddingRight: "5rem",
                    fontSize: "12px",
                  }}
                >
                  INCORPORATION STATE ID
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "700",
                    paddingRight: "5rem",
                    fontSize: "12px",
                  }}
                >
                  NUMBER OF PROPERTIES
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "700",
                    paddingRight: "5rem",
                    fontSize: "12px",
                  }}
                >
                  NUMBER OF TRANSACTION
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "700",
                    paddingRight: "5rem",
                    fontSize: "12px",
                    textAlign: "center",
                  }}
                >
                  REQUEST EDIT ACCESS
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? data.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : data
              ).map((row) => (
                <TableRow key={row.id}>
                  <TableCell>
                    <Checkbox
                      size="small"
                      checked={selectedItems.includes(row.id)}
                      onChange={() => handleSelectRow(row.id)}
                    />
                  </TableCell>
                  <TableCell style={{ fontWeight: "700", fontSize: "14px" }}>
                    {row.owningEntity}
                  </TableCell>
                  <TableCell style={{ fontSize: "14px" }}>
                    {row.informalGroup}
                  </TableCell>
                  <TableCell style={{ fontSize: "14px" }}>
                    {row.incorporationStateId}
                  </TableCell>
                  <TableCell style={{ fontSize: "14px" }}>
                    {row.numOfProperties}
                  </TableCell>
                  <TableCell style={{ fontSize: "14px" }}>
                    {row.numOfTransaction}
                  </TableCell>
                  <TableCell>
                    <img
                      src={editImg}
                      alt="Edit"
                      style={{ display: "block", margin: "0 calc(50% - 40px)" }}
                      className="cursor-pointer"
                      onClick={handleIconClick}
                    />
                  </TableCell>
                </TableRow>
              ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 50 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TableFooter>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50]}
            colSpan={7}
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            SelectProps={{
              inputProps: {
                "aria-label": "rows per page",
              },
              native: true,
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
        </TableFooter>
      </div>
      <div className="w-full my-6 container flex justify-end items-center gap-3">
        <Select
          inputId="fileType"
          placeholder="Type of data"
          className="w-[300px]"
          options={options}
          value={options.find((itm) => itm.value === fileType)}
          onChange={handleSelectChange()}
          noOptionsMessage={() => null}
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              height: "48px",
              borderColor: "black",
            }),
            dropdownIndicator: (baseStyles, state) => ({
              ...baseStyles,
              color: "black",
            }),
          }}
        />
        <button
          disabled={disabled}
          type="button"
          className="border flex gap-2 hover:bg-[#2c4390] disabled:opacity-50 items-center px-4 py-1 h-[3rem] rounded bg-[#203375] text-white"
          onClick={async () => {
            await exportDataFetch(selectedItems, fileType);
          }}
        >
          <img
            src={upgradeImg}
            alt="arrow poined upwards to indicate an upgrade."
          />
          <p>Export Data</p>
        </button>
      </div>
    </>
  );
};

export default CustomTable;
