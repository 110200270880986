import React, { ReactElement } from "react";
import { formDoubleInputTypes } from "../../interfaces/IFormTypes";
import { swapVertImg } from "../../utils/images";

export default function DoubleInput({inputName, inputType, inputOneValue, inputTwoValue,
  onChange, SecondOnChange, className, inputLabelTextContent,
  firstInputPlaceholder, secondInputPlaceholder, onKeyDown }: formDoubleInputTypes): ReactElement {
  return (
    <div className="flex flex-col justify-between gap-2 whitespace-nowrap w-fit">
      <div className="flex items-center justify-between w-full">
        <label
        id={inputName}
        className="text-xs font-normal"
      >
        {inputLabelTextContent?.toUpperCase()}
        </label>
        <img src={swapVertImg} alt="swap icon" className="w-3" />
      </div>
      <div className="flex justify-center items-center gap-3">
      <input
        type={inputType}
        id='start'
        name={inputName}
        value={inputOneValue}
        className={className}
        placeholder={firstInputPlaceholder}
        onChange={onChange}
        onKeyDown={onKeyDown}
        aria-labelledby={inputName}
      />
      <input
        type={inputType}
        id='end'
        name={inputName}
        value={inputTwoValue}
        className={className}
        placeholder={secondInputPlaceholder}
        onKeyDown={onKeyDown}
        onChange={SecondOnChange}
        aria-labelledby={inputName}
      />
      </div>
    </div>
  )
}