import React from 'react';
import { MyButtonProps } from '../../interfaces/ComponentsTypes';

const MyButton: React.FC<MyButtonProps> = ({ text, onClick, className, img, imgAlt, imgWidth, imgHeight }) => {
  return (
    <button onClick={onClick} className={ className }>
      {img ? (<img src={img} alt={imgAlt} width={imgWidth} height={imgHeight}/>) : null}
      <p>{text}</p>
    </button>
  );
};

export default MyButton;