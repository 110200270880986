import React from "react";
import { formInputTypes } from "../../interfaces/IFormTypes";
import {swapVertImg} from "../../utils/images";

const Input: React.FC<formInputTypes> = ({
  inputName,
  inputType,
  inputValue,
  onChange,
  className,
  inputLabelTextContent,
  inputPlaceholder,
  flex,
  imgAlt,
  labelImg,
  inputImg,
  inputImgPadding,
  labelClassname,
  onKeyDown,
  onBlur,
  onFocus,
  onKeyUp
}) => {
  return (
    <div
      className={`${
        flex ? "flex" : "flex flex-col"
      } justify-between relative items-center w-fit gap-2 align-middle`}
    >
      {inputLabelTextContent ? <div className="flex gap-[6px] whitespace-nowrap ">
        <label htmlFor={inputName} className={labelClassname}>
          {inputLabelTextContent}
        </label>
        {labelImg && <img src={swapVertImg} alt={imgAlt} className="w-3" />}
      </div> : null}
      <div className="relative">
        <div className="absolute inset-0 top-[calc(50%-6px)] left-[calc(7%+2px)] pointer-events-none">
          {inputImg && <img src={inputImg} alt="" className="w-3" />}
        </div>
        <input
          type={inputType}
          id={inputName}
          name={inputName}
          value={inputValue}
          className={`${className} ${inputImg ? inputImgPadding : "pl-1"} `}
          placeholder={inputPlaceholder}
          onChange={onChange}
          onKeyDown={onKeyDown}
          onFocus={ () => onFocus}
          onBlur={ () => onBlur}
          onKeyUp={onKeyUp}
        />
      </div>
    </div>
  );
};

export default Input;
