import React, { Dispatch, SetStateAction, useState, useRef, FocusEvent, MouseEvent, useEffect } from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DateCalendar } from '@mui/x-date-pickers';
import { formInputTypes } from '../../interfaces/IFormTypes';
import { swapVertImg } from '../../utils/images';
import { Dayjs } from 'dayjs';
import { formStateObject } from '../../interfaces/IProviders';

interface DatePickerProps extends formInputTypes {
  setFormContent: Dispatch<SetStateAction<formStateObject>>;
  formContent: formStateObject;
}

const CustomDateInput: React.FC<DatePickerProps> = ({
  inputName,
  inputType,
  inputValue,
  onChange,
  className,
  inputLabelTextContent,
  inputPlaceholder,
  flex,
  imgAlt,
  labelImg,
  inputImg,
  inputImgPadding,
  labelClassname,
  onKeyDown,
  setFormContent,
  formContent,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [newValue, setNewValue] = useState<Dayjs | null>(null);
  const datePickerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [isMouseInside, setIsMouseInside] = useState(false)

  const handleMouseEnter = (event: MouseEvent<HTMLDivElement>) => {
    const isChildElement = event.relatedTarget instanceof Node && datePickerRef.current?.contains(event.relatedTarget);

    if (isInputFocused || isChildElement) {
      setIsOpen(true);
    }
  };

  const handleMouseLeave = (event: MouseEvent<HTMLDivElement>) => {
    const isChildElement = event.relatedTarget instanceof Node && datePickerRef.current?.contains(event.relatedTarget);

    if (!isChildElement) {
      setIsOpen(false);
      setIsMouseInside(false);
      if (inputRef.current) {
        inputRef.current.blur(); // Unfocus the input element when the mouse leaves the div
      }
    }

    setIsMouseInside(false);
  };

  const handleBlur = () => {
    setIsInputFocused(false);
    setTimeout(() => {
      if (!datePickerRef.current?.contains(document.activeElement)) {
        setIsOpen(false);
      }
    }, 0);
  };

  const handleFocus = () => {
    setIsInputFocused(true);
    setIsOpen(true);
  };

  const { regDate } = formContent;

  useEffect(() => {
    if (!isMouseInside) {
      setIsOpen(false);
    }
  }, [isMouseInside]);

  return (
    <>
      <div
        className={`${
          flex ? 'flex' : 'flex flex-col'
        } justify-between relative items-center w-fit gap-2 align-middle`}
      >
        <div className="flex gap-[6px] whitespace-nowrap ">
          <label htmlFor={inputName} className={labelClassname}>
            {inputLabelTextContent}
          </label>
          {labelImg && <img src={swapVertImg} alt={imgAlt} className="w-3" />}
        </div>
        <div className="relative">
          <div className="relative">
            <div className="absolute inset-0 top-[calc(50%-6px)] left-[calc(7%+2px)] pointer-events-none">
              {inputImg && <img src={inputImg} alt="" className="w-3" />}
            </div>
            <input
              type={inputType}
              id={inputName}
              name={inputName}
              value={inputValue}
              className={`${className} ${
                inputImg ? inputImgPadding : 'pl-1'
              } text-sm `}
              placeholder={inputPlaceholder}
              onChange={onChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              onKeyDown={onKeyDown}
              ref={inputRef}
            />
            <div
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              ref={datePickerRef}
            >
              <DateCalendar
                className={`bg-white absolute top-[1.85rem] transition-[visible,invisible] will-change-auto z-[102] ${
                  isOpen ? 'visible z-[102]' : 'invisible -z-50'
                }`}
                value={newValue}
                ref={datePickerRef}
                disabled={!isOpen}
                displayWeekNumber={!isOpen}
                onChange={(newValue) => {
                  setNewValue(newValue);
                  setFormContent((prev) => ({
                    ...prev,
                    regDate: {
                      ...regDate,
                      inputValue: newValue ? newValue.format('YYYY-MM-DD') : '',
                    },
                  }));
                  
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomDateInput;
