import React, {
  ChangeEvent,
  KeyboardEvent,
  useEffect,
  useState,
} from "react";
import DoubleInput from "../../components/doubleInput/doubleInput";
import DropdownSelect from "../../components/DropdownSelect";
import FormInput from "../../components/Input";
import FormSelect from "../../components/Selec";
import {
  calendarImg,
  searchImg,
  searchWhiteImg,
  swapVertImg,
  visibilityImg,
} from "../../utils/images";
import ContentHeader from "../../components/contentBar";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import MyButton from "../../components/Button";
import { fetchSearch } from "../../services/Api";
import { Faker, en } from "@faker-js/faker";
import { v4 as uuidv4 } from "uuid";
import { formStateObject } from "../../interfaces/IProviders";
import Modal from "../../components/Modal";
import CustomDateInput from "../../components/DatePicker";
import { Dayjs } from "dayjs";
import { isSameDay } from "date-fns";
import LoadingScreen from "../../components/Loadscreen";

const optionsContent = ["Either", "No", "Yes"];

const statusContent = [ "Select" ,"Registered" , "To be applied" , "Under application"];

const faker = new Faker({ locale: en });

interface DataTypes {
  property: string;
  yetiId: number;
  regNo: number;
  regDate: string;
  currOwner?: string;
  originalClaimant?: string;
  status: string;
  yetiIsNominee?: string;
  numOfTransac: number;
}

const generateRandomName = () => {
  const randomFirstName = faker.company.name();
  // const randomLastName = faker.person.lastName();

  return `${randomFirstName}`;
};

const getRandomYesOrNo = () => {
  const randomBoolean = Math.random() < 0.5;

  if (randomBoolean) {
    return "Yes";
  } else {
    return "No";
  }
};

export interface PageProps {
  isLoading: boolean;
}

const Home: React.FC<PageProps> = ({isLoading}) => {
  const [arrData, setArrData] = useState<DataTypes[]>([]);
  const [fetchSearchLength, setFetchSearchLength] = useState<number>(0);
  const [currOwnerNames, setCurrOwnerNames] = useState<string[]>([]);
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(null);
  const [originalClaimantNames, setOriginalClaimantNames] = useState<string[]>(
    []
  );
  const [yesNo, setYesNo] = useState<string[]>([]);
  const [activeInput, setActiveInput] = useState<string>("");
  const [noResult, setNoResult] = useState<boolean>(false);
  const [formContent, setFormContent] = useState<formStateObject>({
    yetiId: {
      inputValue: "",
      isActive: true,
      text: "YETI ID",
    },
    regNo: {
      inputValue: "",
      isActive: true,
      text: "Reg. No.",
    },
    currOwner: {
      inputValue: "",
      isActive: true,
      text: "Current Owner",
    },
    yetiIsNominee: {
      inputValue: "Either",
      isActive: true,
      text: "YETI is nominee?",
    },
    numOfTransac: {
      inputOneValue: "",
      inputTwoValue: "",
      isActive: true,
      text: "Number of transactions",
    },
    property: {
      inputValue: "",
      isActive: false,
      text: "Property",
    },
    regDate: {
      inputValue: "",
      isActive: true,
      text: "Reg. Date.",
    },
    originalClaimant: {
      inputValue: "",
      isActive: true,
      text: "Original Claimant",
    },
    status: {
      inputValue: "",
      isActive: true,
      text: "Status",
    },
  });
  const [showModal, setShowModal] = useState(false);

  const handleIconClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    const generateNames = () => {
      const currOwner: string[] = [];
      const originalClaimants: string[] = [];
      const yesOrNo: string[] = [];

      for (let i = 0; i < fetchSearchLength; i++) {
        currOwner.push(generateRandomName());
        originalClaimants.push(generateRandomName());
        yesOrNo.push(getRandomYesOrNo());
      }

      setCurrOwnerNames(currOwner);
      setOriginalClaimantNames(originalClaimants);
      setYesNo(yesOrNo);
    };
    
    generateNames();
  }, [fetchSearchLength]);

  const {
    yetiId,
    currOwner,
    numOfTransac,
    originalClaimant,
    property,
    regDate,
    regNo,
    status,
    yetiIsNominee,
  } = formContent;

  useEffect(() => {
    const getData = async () => {
      const get = await fetchSearch();
      setFetchSearchLength((prev) => (prev = get.length));
      const changeData = get.map((itm, index) => ({
        property: itm.assetName,
        yetiId: itm.assetIDNumber,
        regNo: itm.assetIDNumber,
        regDate: itm.assetRegistrationDate,
        numOfTransac: itm.numberOfTransaction,
        status: itm.assetStatus,
        currOwner: itm.partyAssociateNames[0],
        originalClaimant: originalClaimantNames[index],
        yetiIsNominee: yesNo[index],
      }));

      setArrData(changeData);
    };

    
    const {
      property,
      yetiId,
      regNo,
      regDate,
      currOwner,
      originalClaimant,
      status,
      yetiIsNominee,
      numOfTransac,
    } = formContent;

    if (
      property.inputValue ||
      yetiId.inputValue ||
      regNo.inputValue ||
      regDate.inputValue ||
      currOwner.inputValue ||
      originalClaimant.inputValue ||
      status.inputValue ||
      yetiIsNominee.inputValue ||
      (numOfTransac.inputOneValue !== null &&
        numOfTransac.inputTwoValue !== null)
    ) {
      getData();
    }
  }, [formContent, currOwnerNames, originalClaimantNames, yesNo]);

  const handleFilter = () => {
    const filteredData = arrData.filter((item) => {
      let isMatch = true;
  
      if (
        formContent.property.inputValue &&
        !String(item.property).toLowerCase().includes(String(formContent.property.inputValue).toLowerCase())
      ) {
        isMatch = false;
      }
  
      if (
        formContent.yetiId.inputValue &&
        !String(item.yetiId).toLowerCase().includes(String(formContent.yetiId.inputValue).toLowerCase())
      ) {
        isMatch = false;
      }
  
      if (
        formContent.regNo.inputValue &&
        !String(item.regNo).toLowerCase().includes(String(formContent.regNo.inputValue).toLowerCase())
      ) {
        isMatch = false;
      }
  
      if (
        formContent.regDate.inputValue &&
        !isSameDay(new Date(item.regDate), new Date(formContent.regDate.inputValue))
      ) {
        isMatch = false;
      }
  
      if (
        formContent.currOwner.inputValue &&
        !String(item.currOwner).toLowerCase().includes(String(formContent.currOwner.inputValue).toLowerCase())
      ) {
        isMatch = false;
      }
  
      if (
        formContent.originalClaimant.inputValue &&
        !String(item.originalClaimant).toLowerCase().includes(String(formContent.originalClaimant.inputValue).toLowerCase())
      ) {
        isMatch = false;
      }
  
      if (
        formContent.status.inputValue &&
        String(item.status).toLowerCase() !== String(formContent.status.inputValue).toLowerCase()
      ) {
        isMatch = false;
      }
  
      if (
        formContent.yetiIsNominee.inputValue &&
        String(formContent.yetiIsNominee.inputValue).toLowerCase() !== "either" &&
        String(item.yetiIsNominee).toLowerCase() !== String(formContent.yetiIsNominee.inputValue).toLowerCase()
      ) {
        isMatch = false;
      }
  
      if (
        (formContent.numOfTransac.inputOneValue !== "" || formContent.numOfTransac.inputTwoValue !== "") &&
        ((formContent.numOfTransac.inputOneValue !== "" && item.numOfTransac < Number(formContent.numOfTransac.inputOneValue)) ||
          (formContent.numOfTransac.inputTwoValue !== "" && item.numOfTransac > Number(formContent.numOfTransac.inputTwoValue)))
      ) {
        isMatch = false;
      }
  
      setActiveInput("");
  
      return isMatch;
    });
  
    if (filteredData.length === 0) {
      setNoResult(true);
    } else {
      setNoResult(false);
    }
  
    setArrData(filteredData);
  };

  if (isLoading) {
    return (<LoadingScreen />)
  }

  return (
    <section className="relative container mx-auto">
      { showModal && (<>
          <div className="fixed inset-0 bg-black opacity-50 z-[99]" onClick={handleCloseModal} />
          <Modal onClose={handleCloseModal} />
        </>)}
      <h1 className=" text-[#203375] text-5xl font-[700] text-center h-20 my-6">
        The Universal System for Identification Protocols
      </h1>
      <ContentHeader className="relative z-20">
        <h1 className=" text-4xl font-bold text-[#203375]">Assets</h1>
        <DropdownSelect
          formContent={formContent}
          setFormContent={setFormContent}
        />
      </ContentHeader>
      <TableContainer
        style={{
          maxHeight: "calc(100vh - 300px)",
          minHeight: "calc(100vh - 300px)",
          width: "100%",
          backgroundColor: "white",
          borderRadius: "5px",
          marginTop: "20px",
        }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {property.isActive ? (
                <TableCell width={112}>
                  <FormInput
                    inputImgPadding="pl-6"
                    inputName="property"
                    labelClassname="text-xs font-normal text-left"
                    inputValue={formContent.property.inputValue}
                    inputType="text"
                    inputLabelTextContent="Property"
                    inputPlaceholder="Search"
                    inputImg={searchImg}
                    labelImg={swapVertImg}
                    imgAlt="swap icon"
                    onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
                      if (e.key === "Enter") {
                        handleFilter();
                        setNoResult(false);
                      }
                    }}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setActiveInput("property");
                      setFormContent((prev) => ({
                        ...prev,
                        property: {
                          inputValue: e.target.value,
                          isActive: prev.property.isActive,
                          text: property.text,
                        },
                      }));

                    }}
                    className="my-1 text-sm font-normal border rounded w-[74px] mx-1 border-slate-400 h-[24px]  placeholder:text-xs text-[#3C4045] placeholder:text-[#3C4045]"
                  />
                </TableCell>
              ) : null}
              {yetiId.isActive ? (
                <TableCell width={112} align="left">
                  <FormInput
                    inputImgPadding="pl-5"
                    inputName="yetiId"
                    labelClassname="text-xs font-normal text-left"
                    inputValue={formContent.yetiId.inputValue}
                    inputType="number"
                    inputLabelTextContent="YETI ID"
                    inputPlaceholder="Search"
                    inputImg={searchImg}
                    labelImg={swapVertImg}
                    imgAlt="swap icon"
                    onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
                      if (e.key === "Enter") {
                        handleFilter();
                      }
                    }}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setActiveInput("yetiId");
                      setFormContent((prev) => ({
                        ...prev,
                        yetiId: {
                          inputValue: e.target.value,
                          isActive: prev.yetiId.isActive,
                          text: yetiId.text,
                        },
                      }));
                    }}
                    className="block my-1 text-sm font-normal border rounded w-[74px] mx-1 border-slate-400 h-[24px]  placeholder:text-xs text-[#3C4045] placeholder:text-[#3C4045]"
                  />
                </TableCell>
              ) : null}
              {regNo.isActive ? (
                <TableCell width={130}>
                  <FormInput
                    inputImgPadding="pl-6"
                    inputName="regNo"
                    labelClassname="text-xs font-normal"
                    inputValue={formContent.regNo.inputValue}
                    inputType="number"
                    inputLabelTextContent="REG. NUMBER"
                    inputPlaceholder="Search"
                    inputImg={searchImg}
                    labelImg={swapVertImg}
                    imgAlt="swap icon"
                    onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
                      if (e.key === "Enter") {
                        handleFilter();
                      }
                    }}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setNoResult(false);
                      setFormContent((prev) => ({
                        ...prev,
                        regNo: {
                          inputValue: e.target.value,
                          isActive: prev.regNo.isActive,
                          text: regNo.text,
                        },
                      }));
                      setActiveInput("regNo");
                    }}
                    className=" block my-1  text-sm font-normal border rounded w-[120px] mx-1 border-slate-400 h-[24px]  placeholder:text-xs text-[#3C4045] placeholder:text-[#3C4045]"
                  />
                </TableCell>
              ) : null}
              {regDate.isActive ? (
                <TableCell width={130}>
                  <CustomDateInput
                    inputImgPadding="pl-5"
                    inputName="regDate"
                    labelClassname="text-xs font-normal"
                    inputValue={formContent.regDate.inputValue}
                    inputType="text"
                    inputLabelTextContent="REG. DATE"
                    inputPlaceholder="Search"
                    inputImg={calendarImg}
                    labelImg={swapVertImg}
                    imgAlt="swap icon"
                    setFormContent={setFormContent}
                    formContent={formContent}
                    onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
                      if (e.key === "Enter") {
                        handleFilter();
                      }
                      if (e.key === "Backspace") {
                        setFormContent(prev => ({
                          ...prev, regDate: {
                            ...regDate, inputValue: ''
                          }
                        }))
                      }
                    }}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setNoResult(false);
                      setFormContent((prev) => ({
                        ...prev,
                        regDate: {
                          inputValue: selectedDate?.format('YYYY-MM-DD'),
                          isActive: prev.regDate.isActive,
                          text: regDate.text,
                        },
                      }));

                      setActiveInput("regDate");
                    }}
                    className=" block my-1  text-sm font-normal border rounded w-[84px] mx-1 border-slate-400 h-[24px]  placeholder:text-xs text-[#3C4045] placeholder:text-[#3C4045]"
                  />
                </TableCell>
              ) : null}
              {currOwner.isActive ? (
                <TableCell width={124}>
                  <FormInput
                    inputImgPadding="pl-6"
                    inputName="currOwner"
                    labelClassname="text-xs font-normal"
                    inputValue={formContent.currOwner.inputValue}
                    inputType="text"
                    inputLabelTextContent="CURRENT OWNER"
                    inputPlaceholder="Search"
                    inputImg={searchImg}
                    labelImg={swapVertImg}
                    imgAlt="swap icon"
                    onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
                      if (e.key === "Enter") {
                        handleFilter();
                      }
                    }}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setNoResult(false);
                      setFormContent((prev) => ({
                        ...prev,
                        currOwner: {
                          inputValue: e.target.value,
                          isActive: prev.currOwner.isActive,
                          text: currOwner.text,
                        },
                      }));

                      setActiveInput("currOwner");
                    }}
                    className=" block my-1  text-sm font-normal border rounded w-[124px] mx-1 border-slate-400 h-[24px]  placeholder:text-xs text-[#3C4045] placeholder:text-[#3C4045]"
                  />
                </TableCell>
              ) : null}
              {originalClaimant.isActive ? (
                <TableCell width={142}>
                  <FormInput
                    inputImgPadding="pl-6"
                    inputName="originalClaimant"
                    labelClassname="text-xs font-normal"
                    inputValue={formContent.originalClaimant.inputValue}
                    inputType="text"
                    inputLabelTextContent="ORIGINAL CLAIMANT"
                    inputPlaceholder="Search"
                    inputImg={searchImg}
                    labelImg={swapVertImg}
                    imgAlt="swap icon"
                    onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
                      if (e.key === "Enter") {
                        handleFilter();
                      }
                    }}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setNoResult(false);
                      setFormContent((prev) => ({
                        ...prev,
                        originalClaimant: {
                          inputValue: e.target.value,
                          isActive: prev.originalClaimant.isActive,
                          text: originalClaimant.text,
                        },
                      }));

                      setActiveInput("originalClaimant");
                    }}
                    className=" block my-1  text-sm font-normal border rounded w-[142px] mx-1 border-slate-400 h-[24px]  placeholder:text-xs text-[#3C4045] placeholder:text-[#3C4045]"
                  />
                </TableCell>
              ) : null}
              {status.isActive ? (
                <TableCell width={84}>
                  <FormSelect
                    selectName="status"
                    selectOptions={statusContent}
                    selectValue={formContent.status.inputValue}
                    onKeyDown={(e: KeyboardEvent<HTMLSelectElement>) => {
                      if (e.key === "Enter") {
                        handleFilter();
                      }
                    }}
                    onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                      setNoResult(false);
                      setFormContent((prev) => ({
                        ...prev,
                        status: {
                          inputValue: e.target.value,
                          isActive: prev.status.isActive,
                          text: status.text,
                        },
                      }));

                      setActiveInput("status");
                    }}
                    selectLabelTextContent="STATUS"
                    className=" block my-1 p-0.5 text-xs font-normal text-[#3C4045] placeholder:text-[#3C4045] border rounded w-[84px] mx-1 h-[24px] border-slate-400  placeholder:text-xs"
                  />
                </TableCell>
              ) : null}
              {yetiIsNominee.isActive ? (
                <TableCell width={120}>
                  <FormSelect
                    selectName="nominee"
                    selectOptions={optionsContent}
                    selectValue={formContent.yetiIsNominee.inputValue}
                    onKeyDown={(e: KeyboardEvent<HTMLSelectElement>) => {
                      if (e.key === "Enter") {
                        handleFilter();
                      }
                    }}
                    onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                      setNoResult(false);
                      setFormContent((prev) => ({
                        ...prev,
                        yetiIsNominee: {
                          inputValue: e.target.value,
                          isActive: prev.yetiIsNominee.isActive,
                        },
                      }));

                      setActiveInput("nominee");
                    }}
                    selectLabelTextContent="YETI IS NOMINEE"
                    className=" block self-start my-1  p-0.5 text-xs font-normal border text-[#3C4045] placeholder:text-[#3C4045] rounded w-[74px] mx-1 border-slate-400  placeholder:text-xs"
                  />
                </TableCell>
              ) : null}
              {numOfTransac.isActive ? (
                <TableCell width={168}>
                  <DoubleInput
                    inputName="NumOfTransactions"
                    firstInputPlaceholder="min"
                    secondInputPlaceholder="max"
                    inputOneValue={formContent.numOfTransac.inputOneValue}
                    inputTwoValue={formContent.numOfTransac.inputTwoValue}
                    inputType="text"
                    inputLabelTextContent="N. of Transaction"
                    className="my-1 pl-2 text-[#3C4045] placeholder:text-[#3C4045] w-full py-0.5 placeholder:text-xs  text-sm font-normal h-[24px] border rounded border-slate-400"
                    onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
                      if (e.key === "Enter") {
                        handleFilter();
                      }
                    }}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setNoResult(false);
                      setFormContent((prev) => ({
                        ...prev,
                        numOfTransac: {
                          inputOneValue: e.target.value,
                          inputTwoValue: prev.numOfTransac.inputTwoValue,
                          isActive: prev.numOfTransac.isActive,
                          text: numOfTransac.text,
                        },
                      }));

                      setActiveInput("NumOfTransactions");
                    }}
                    SecondOnChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setNoResult(false);
                      setFormContent((prev) => ({
                        ...prev,
                        numOfTransac: {
                          inputTwoValue: e.target.value,
                          inputOneValue: prev.numOfTransac.inputOneValue,
                          isActive: prev.numOfTransac.isActive,
                          text: numOfTransac.text,
                        },
                      }));

                      setActiveInput("NumOfTransactions");
                    }}
                  />
                </TableCell>
              ) : null}
              <TableCell width={130} align="center">
                <p className="text-xs mb-[2.3rem] font-normal whitespace-nowrap">
                  CHAIN OF TITLE
                </p>
              </TableCell>
              <TableCell width={130}>
                <MyButton
                  text="Search"
                  img={searchWhiteImg}
                  className="flex bg-[#203375] hover:bg-[#2c4390] py-1 px-3 rounded text-white fill-white align-middle text-xs items-center gap-4 mb-[2.2rem]"
                  imgAlt="search icon"
                  imgWidth={14}
                  imgHeight={14}
                  onClick={() => handleFilter()}
                />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {noResult ? (
              <TableRow>
                <TableCell
                  colSpan={10}
                  align="center"
                  style={{ backgroundColor: "#ffb5b5", padding: "50px" }}
                >
                  <p className="text-xl font-semibold">No Result Found</p>
                </TableCell>
              </TableRow>
            ) : (
              arrData.map((item) => (
                <TableRow key={uuidv4()}>
                  {property.isActive ? (
                    <TableCell>
                      <p className="font-bold">{item.property}</p>
                    </TableCell>
                  ) : null}
                  {yetiId.isActive ? (
                    <TableCell>
                      <p
                        className={`${
                          property.isActive ? "font-normal" : "font-bold"
                        }`}
                      >
                        {item.yetiId}
                      </p>
                    </TableCell>
                  ) : null}
                  {regNo.isActive ? (
                    <TableCell>
                      <p>{item.regNo}</p>
                    </TableCell>
                  ) : null}
                  {regDate.isActive ? (
                    <TableCell>
                      <p>{item.regDate}</p>
                    </TableCell>
                  ) : null}
                  {currOwner.isActive ? (
                    <TableCell>{item.currOwner}</TableCell>
                  ) : null}
                  {originalClaimant.isActive ? (
                    <TableCell>
                      <p>{item.originalClaimant}</p>
                    </TableCell>
                  ) : null}
                  {status.isActive ? (
                    <TableCell align="center">
                      <p className="bg-[#D9E7FB] hover:bg-[#79CDF1] rounded-full p-1">
                        {item.status}
                      </p>
                    </TableCell>
                  ) : null}
                  {yetiIsNominee.isActive ? (
                    <TableCell>
                      <p>{item.yetiIsNominee}</p>
                    </TableCell>
                  ) : null}
                  {numOfTransac.isActive ? (
                    <TableCell>
                      <p>{item.numOfTransac}</p>
                    </TableCell>
                  ) : null}
                  <TableCell>
                    <div onClick={ () => handleIconClick() }>
                      <img
                        src={visibilityImg}
                        alt="icon of an eye to indicate visibility"
                        style={{
                          display: "block",
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                      />
                    </div>
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </section>
  );
};

export default Home;
