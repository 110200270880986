import React, { useState } from 'react';
import MyButton from '../../components/Button';
import { uploadFile } from '../../services/Api';
import ContentHeader from '../../components/contentBar';
import FileInput from '../../components/FileInput';
import { downloadImg, upgradeImg } from '../../utils/images';
import { PageProps } from '../Home';
import LoadingScreen from '../../components/Loadscreen';

const Import: React.FC<PageProps> = ({isLoading}) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [response, setResponse] = useState('');

  const handleFileChange = (file: File | null) => {
    setSelectedFile(file);
  };

  const handleDownload = async () => {
    const response = await fetch('/TemplateImport.xlsm');

    const fileContent = await response.blob();

    const url = URL.createObjectURL(fileContent);

    const link = document.createElement('a');

    link.href = url;

    link.download = `template.xlsm`;

    link.click();

    URL.revokeObjectURL(url);
  };

  if (isLoading) {
    return (<LoadingScreen />)
  }

  return (
    <section className="container mx-auto my-16">
      <ContentHeader className="pl-[calc(40%-250px)]">
        <h1 className="text-3xl font-bold text-[#203375]">Import Data</h1>
      </ContentHeader>
      <div className="flex flex-col py-6 relative">
        <div className="flex flex-col py-4">
          <div className="flex flex-col justify-center gap-4 mt-14 items-center mx-auto w-fit align-middle">
            <div className='flex justify-center gap-4 items-center mx-auto w-fit align-middle'>
            <FileInput onChange={handleFileChange} />
            <MyButton
              text="Import Data"
              img={downloadImg}
              imgAlt='Download icon'
              onClick={async () => {
                if (selectedFile) {
                  const message = await uploadFile(selectedFile);
                  setResponse((prev) => message);
                }
              }}
              className="flex items-center gap-2 bg-[#009D2C] hover:bg-[#11b33f] text-white rounded px-[7.4rem] py-3 font-bold"
            />
            </div>
            <MyButton
              text="Excel Template"
              onClick={async () => await handleDownload()}
              img={upgradeImg}
              imgAlt='Upward arrow icon'
              imgWidth={10}
              className="flex items-center gap-2 bg-[#91A3E1] hover:bg-[#2c4390] px-6 h-12 rounded text-slate-100 font-medium self-end"
            />
          </div>
          {response ? (
            <div className="m-auto text-lg font-semibold">{response}</div>
            ) : null}
        </div>
      </div>
    </section>
  );
};

export default Import;
