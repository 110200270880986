import React, { Dispatch, SetStateAction, useContext, useState } from "react";
import { arrowDownImg, checkImg, viewWeekImg } from "../../utils/images";
import { formStateObject } from "../../interfaces/IProviders";

interface DropwdownSelectTypes {
  formContent: formStateObject;
  setFormContent: Dispatch<SetStateAction<formStateObject>>;
}

const DropdownSelect: React.FC<DropwdownSelectTypes> = ({formContent, setFormContent}) => {
  const [isOpen, setIsOpen] = useState(false)

  const { yetiId, currOwner, numOfTransac,
    originalClaimant, property, regDate,
    regNo, status, yetiIsNominee } = formContent;

  return (
    <div className="flex text-xs cursor-pointer flex-col border bg-white border-slate-800 absolute right-[calc(10%-5.5vw)] top-5 items-center rounded ">
      <div onClick={ () => setIsOpen(prev => !prev) }
      className=' flex my-auto items-center gap-2 w-[203px] h-[34px] p-2 rounded  justify-between'>
      <img src={viewWeekImg} className="mt-1" alt="icon to indicate columns" width={13} />
      <p className="ml-[-32px] text-base ">Select Columns</p>
      <img src={arrowDownImg} className={`mt-1 ${isOpen ? '' : ''}`} alt="arrow down icon"  width={12}/>
      </div>
      {isOpen ? (
        <div className="flex flex-col w-[203px] rounded-md bg-white">
          <div onClick={ () => setFormContent(prev => ({ ...prev, property: { ...property, isActive: !prev.property.isActive, text: property.text }})) }
          className={`flex justify-between  p-2 ${property.isActive ? 'bg-[#D9E7FB]': 'bg-white' }`}>
            <p>{property.text}</p>
            <div>
              {property.isActive ? <img src={checkImg} alt="checkbox icon" /> : null }
            </div>
          </div>
          <div onClick={ () => setFormContent(prev => ({ ...prev, yetiId: { ...yetiId, isActive: !prev.yetiId.isActive}})) }
          className={`flex justify-between  p-2 ${yetiId.isActive ? 'bg-[#D9E7FB]': 'bg-white' }`}>
            <p>{yetiId.text}</p>
            <div>
              {yetiId.isActive ? <img src={checkImg} alt="checkbox icon" /> : null }
            </div>
          </div>
          <div onClick={ () => setFormContent(prev => ({ ...prev, regNo: { ...regNo, isActive: !prev.regNo.isActive}})) }
          className={`flex justify-between  p-2 ${regNo.isActive ? 'bg-[#D9E7FB]': 'bg-white' }`}>
            <p>{regNo.text}</p>
            <div>
              {regNo.isActive ? <img src={checkImg} alt="checkbox icon" /> : null }
            </div>
          </div>
          <div onClick={ () => setFormContent(prev => ({ ...prev, regDate: { ...regDate, isActive: !prev.regDate.isActive}})) }
          className={`flex justify-between  p-2 ${regDate.isActive ? 'bg-[#D9E7FB]': 'bg-white' }`}>
            <p>{regDate.text}</p>
            <div>
              {regDate.isActive ? <img src={checkImg} alt="checkbox icon" /> : null }
            </div>
          </div>
          <div onClick={ () => setFormContent(prev => ({ ...prev, currOwner: { ...currOwner, isActive: !prev.currOwner.isActive}})) }
          className={`flex justify-between  p-2 ${currOwner.isActive ? 'bg-[#D9E7FB]': 'bg-white' }`}>
            <p>{currOwner.text}</p>
            <div>
              {currOwner.isActive ? <img src={checkImg} alt="checkbox icon" /> : null }
            </div>
          </div>
          <div onClick={ () => setFormContent(prev => ({ ...prev, originalClaimant: { ...originalClaimant, isActive: !prev.originalClaimant.isActive}})) }
          className={`flex justify-between  p-2 ${originalClaimant.isActive ? 'bg-[#D9E7FB]': 'bg-white' }`}>
            <p>{originalClaimant.text}</p>
            <div>
              {originalClaimant.isActive ? <img src={checkImg} alt="checkbox icon" /> : null }
            </div>
          </div>
          <div onClick={ () => setFormContent(prev => ({ ...prev, status: { ...status, isActive: !prev.status.isActive}})) }
          className={`flex justify-between  p-2 ${status.isActive ? 'bg-[#D9E7FB]': 'bg-white' }`}>
            <p>{status.text}</p>
            <div>
              {status.isActive ? <img src={checkImg} alt="checkbox icon" /> : null }
            </div>
          </div>
          <div onClick={ () => setFormContent(prev => ({ ...prev, yetiIsNominee: { ...yetiIsNominee, isActive: !prev.yetiIsNominee.isActive}})) }
          className={`flex justify-between  p-2 ${yetiIsNominee.isActive ? 'bg-[#D9E7FB]': 'bg-white' }`}>
            <p>{yetiIsNominee.text}</p>
            <div>
              {yetiIsNominee.isActive ? <img src={checkImg} alt="checkbox icon" /> : null }
            </div>
          </div>
          <div onClick={ () => setFormContent(prev => ({ ...prev, numOfTransac: { ...numOfTransac, isActive: !prev.numOfTransac.isActive}})) }
          className={`flex justify-between  p-2 ${numOfTransac.isActive ? 'bg-[#D9E7FB]': 'bg-white' }`}>
            <p>{numOfTransac.text}</p>
            <div>
              {numOfTransac.isActive ? <img src={checkImg} alt="checkbox icon" /> : null }
            </div>
          </div>
        </div>
        
      ) : null }
    </div>
  )
}

export default DropdownSelect;