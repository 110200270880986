import React, { useCallback, useContext, useEffect, useState } from "react";
import Select, { ActionMeta, SingleValue } from "react-select";
import Input from "../../components/Input";
import { TransactionForm, customOption, formKeys } from '../../interfaces/ITransactionTypes'
import Table from "../../components/ImportTable";
import MyContext from "../../providers/contexts/GlobalContext";
import { fetchPartysById } from "../../services/Api";
import { ImportTableData } from "../../interfaces/ComponentsTypes";
import ContentHeader from "../../components/contentBar";
import { calendarImg } from "../../utils/images";
import TransactionTable from "../../components/TransactionTable";
import { PageProps } from "../Home";

const optionsTransc = [
  { id: '1' ,value: 'Loan', label: 'Loan' },
  { id: '2' ,value: 'Assign', label: 'Assign' },
  { id: '3' ,value: 'Transfer', label: 'Transfer' },
  { id: '4' ,value: 'Financing', label: 'Financing' },
  { id: '5' ,value: 'Release of Financing', label: 'Release of Financing' },
  { id: '6' ,value: 'License', label: 'License' },
  { id: '7' ,value: 'Revocation of License', label: 'Revocation of License' },
  { id: '8' ,value: 'Affidavit of Ownership', label: 'Affidavit of Ownership' }
];


const Transaction: React.FC<PageProps> = ({isLoading}) => {
  return <TransactionTable isLoading={isLoading} />
  // const { partyNames, partyOneFetch, setPartyOneFetch } = useContext(MyContext);
  // const [transactionForm, setTransactionForm] = useState<TransactionForm>({
  //   partyOne: {
  //     id: 0,
  //     inputValue: '',
  //   },
  //   yetiTranscType: {
  //     inputValue: '',
  //   },
  //   transacDateSigned: {
  //     inputValue: '',
  //   },
  //   partyTwo: {
  //     id: 0,
  //     inputValue: '',
  //   },
  //   transacDescription: {
  //     inputValue: '',
  //   },
  //   transacSrcNum: {
  //     inputValue: '',
  //   },
  // });

  // const newData = (): ImportTableData[] => {
  //   const newArr = partyOneFetch.map((itm, i) => ({ 
  //   id: i,
  //   isSelected: false,
  //   propertyName: itm.assetName,
  //   yetiID: `YETI-ID`}));
  //   return newArr;
  // }
  

  // const data: ImportTableData[] = newData();

  // const options = partyNames.map((itm) => ({ id: itm.partyID.toString() , value: itm.partyName, label: itm.label}))

  // const getPartyId = useCallback((id: number) => {
  //   const find = partyNames.find(itm => itm.partyID === id);
  //   return find?.partyID;
  // }, [partyNames]);

  // useEffect(() => {
  //   if (transactionForm.partyOne.inputValue !== '' || transactionForm.partyOne.inputValue.length > 0) {
  //     const partyOneInput = transactionForm.partyOne.id
  //     const formId = Number(getPartyId(Number(partyOneInput)))
  //     const fetchedAsset = async (id: number) => {
  //         const {response} = await fetchPartysById(id);
  //         setPartyOneFetch(prev => prev = response)
  //     }

  //     fetchedAsset(formId)
  //   }
  // }, [transactionForm.partyOne.inputValue, getPartyId, setPartyOneFetch, transactionForm.partyOne.id]);

  // const handleSelectChange = (key: formKeys) => (selectedOption: SingleValue<customOption>, actionMeta: ActionMeta<customOption>): void => {
  //   setTransactionForm(prevState => ({
  //     ...prevState,
  //     [key]: {
  //       ...prevState[key],
  //       inputValue: selectedOption?.value ?? '',
  //       id: selectedOption?.id ?? ''
  //     },
  //   }));
  // };
  

  // return (
  //   <section className="container justify-center m-auto mt-7 flex flex-col items-center align-middle">
  //     <ContentHeader className="pl-[calc(40%-325px)]">
  //       <h3 className="text-3xl font-bold text-[#203375]">Transaction of Property #</h3>
  //     </ContentHeader>
  //     <form className="flex flex-col mx-auto mt-7 gap-7 items-center">
  //       <fieldset className="flex gap-3 items-center">
  //       <div className="flex flex-col gap-2">
  //         <label htmlFor="partyOne">Party 1*</label>
  //         <Select
  //             inputId="partyOne"
  //             placeholder='Party Name'
  //             className="w-[300px]"
  //             value={options.find(option => option.value === transactionForm.partyOne.inputValue)}
  //             options={options}
  //             onChange={handleSelectChange(formKeys.partyOne)}
  //             blurInputOnSelect
  //             isClearable
  //             styles={{ control: (base, props) => ({ ...base, height: '48px', borderColor: '#3C4045'}),
  //             dropdownIndicator: (base, props) => ({ ...base, color: 'black'})}}
  //         />
  //       </div>
  //       <div className="flex flex-col gap-2">
  //         <label htmlFor="partyTwo">Party 2*</label>
  //         <Select
  //             inputId="partyTwo"
  //             placeholder='Party Name'
  //             className="w-[300px]"
  //             value={options.find(option => option.value === transactionForm.partyTwo.inputValue)}
  //             options={options}
  //             onChange={handleSelectChange(formKeys.partyTwo)}
  //             blurInputOnSelect
  //             isClearable
  //             styles={{ control: (base, props) => ({ ...base, height: '48px', borderColor: '#3C4045'}),
  //             dropdownIndicator: (base, props) => ({ ...base, color: 'black'})}}
  //           />
  //         </div>
  //       <div className="flex flex-col gap-2">
  //         <label htmlFor="yetiTranscType">YETI Transaction Type*</label>
  //         <Select
  //             inputId="yetiTranscType"
  //             className="w-[300px]"
  //             value={options.find(option => option.value === transactionForm.yetiTranscType.inputValue)}
  //             options={optionsTransc}
  //             onChange={handleSelectChange(formKeys.yetiTranscType)}
  //             blurInputOnSelect
  //             isClearable
  //             isSearchable={false}
  //             styles={{ control: (base, props) => ({ ...base, height: '48px', borderColor: '#3C4045'}),
  //             dropdownIndicator: (base, props) => ({ ...base, color: 'black'})}}
  //           />
  //         </div>
  //       </fieldset>
  //       <fieldset className="flex gap-3 items-center mx-auto">
  //         <Input
  //             inputName="transacDescription"
  //             inputType="text"
  //             className="w-[297px] p-2 h-[47px] outline outline-1 transition-all focus:outline-blue-500 focus:outline-2 will-change-auto outline-[#3C4045] rounded pl-3"
  //             labelClassname="ml-[-6px] mr-[8.1rem]"
  //             inputPlaceholder="Party Assigning YETI"
  //             inputLabelTextContent="Transaction Description"
  //             inputValue={transactionForm.transacDescription.inputValue}
  //             onChange={(e: React.ChangeEvent<HTMLInputElement>) => setTransactionForm(prev => ({ ...prev, transacDescription: { inputValue: e.target.value } } ) ) }
  //           />
  //           <Input
  //             inputName="transacDateSigned"
  //             inputType="text"
  //             className="w-[297px] p-2 h-[47px] outline outline-1 transition-all focus:outline-blue-500 focus:outline-2 will-change-auto outline-[#3C4045] rounded pl-3"
  //             inputPlaceholder="Select"
  //             flex={false}
  //             inputImg={calendarImg}
  //             inputImgPadding="pl-11"
  //             imgAlt="calender icon"
  //             labelClassname="mr-[8.1rem]"
  //             inputLabelTextContent="Transaction Date Signed"
  //             inputValue={transactionForm.transacDateSigned.inputValue}
  //             onChange={(e: React.ChangeEvent<HTMLInputElement>) => setTransactionForm(prev => ({ ...prev, transacDateSigned: { inputValue: e.target.value } } ) ) }
  //           />
  //           <Input
  //             inputName="transacSrcNum"
  //             inputType="text"
  //             className="w-[297px] p-2 h-[47px] outline outline-1 transition-all focus:outline-blue-500 focus:outline-2 will-change-auto outline-[#3C4045] rounded pl-3"
  //             labelClassname="ml-[-10px] pr-24"
  //             inputPlaceholder="XXXXXXXXXXXXXX-X"
  //             inputLabelTextContent="Transaction Source Number"
  //             inputValue={transactionForm.transacSrcNum.inputValue}
  //             onChange={(e: React.ChangeEvent<HTMLInputElement>) => setTransactionForm(prev => ({ ...prev, transacSrcNum: { inputValue: e.target.value } } ) ) }
  //           />
  //       </fieldset>
  //     </form>
  //     <Table
  //       data={data}
  //       partyOneFetch={partyOneFetch}
  //       partyOne={Number(transactionForm.partyOne.id)}
  //       partyTwo={Number(transactionForm.partyTwo.id)}
  //       isTransaction={transactionForm.transacSrcNum.inputValue.length > 0 ? 'native' : 'yeti'}
  //       transactionYETIComments={transactionForm.transacDescription.inputValue}
  //       transactionYETIDateSigned={transactionForm.transacDateSigned.inputValue}
  //       transactionYETIType={transactionForm.yetiTranscType.inputValue}
  //       srcNumber={transactionForm.transacSrcNum.inputValue}
  //        />
  //   </section>
};

export default Transaction;
