import React, { useEffect, useState } from "react";
import MyContext from "./contexts/GlobalContext";
import {
  assetTypes,
  IGlobalProvider,
  IPartyNames,
} from "../interfaces/IProviders";
import { fetchPartyNames } from "../services/Api";
import { partyFetchTypes } from "../interfaces/IAPITypes";

const MyProvider: React.FC<IGlobalProvider> = ({ children }) => {
  const [partyNames, setPartyNames] = useState<IPartyNames[]>([]);
  const [partyOneFetch, setPartyOneFetch] = useState<assetTypes[]>([]);

  useEffect(() => {
    const partys = async () => {
      const { response } = await fetchPartyNames();
      const addLabel = response.map((itm: partyFetchTypes) => ({
        ...itm,
        label: itm.partyName,
      }));
      setPartyNames(addLabel);
    };

    partys();
  }, []);

  const contextObject = {
    partyNames,
    setPartyNames,
    partyOneFetch,
    setPartyOneFetch,
  };

  return (
    <MyContext.Provider value={contextObject}>{children}</MyContext.Provider>
  );
};

export default MyProvider;
